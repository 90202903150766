<template>
  <span class="inline-flex items-center">
    <y-avatar
      :player="player"
      class="mr-2" />

    <span class="leading-4">
      {{ player.name }}
      <span
        v-if="details"
        class="block text-xs text-gray-400">
        <span v-if="player.stats.nbVictory">{{ $filters.pluralize(player.stats.nbVictory, 'victoire') }}</span>
        <span v-if="player.stats.nbVictory && player.stats.nbDefeat">, </span>
        <span v-if="player.stats.nbDefeat">{{ $filters.pluralize(player.stats.nbDefeat, 'défaite') }}</span>
      </span>
    </span>
  </span>
</template>

<script>
import YAvatar from '@/UI/YAvatar.vue';

export default {

  components: {
    YAvatar,
  },

  props: {
    player: {
      type: Object,
      required: true,
    },
    details: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

};
</script>
