<template>
  <div class="h-40">
    <div ref="root" />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, watch, computed, onMounted } from 'vue';
import Chartist from 'chartist';

export default {

  props: {
    player: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    season: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const root = ref(null);

    const baseData = () => ({
      labels: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
      series: [
        {
          className: 'fill-current text-green-500',
          data: [0, 0, 0, 0, 0, 0, 0],
        },
        {
          className: 'fill-current text-red-500',
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
    });

    const data = computed(() => {
      const d = baseData();

      if (props.player?.games) {
        Object.keys(props.player.games).forEach((id) => {
          const game = props.player?.games?.[id];
          const timeStamp = props.event?.games?.[id];

          if (timeStamp && (!props.season || (props.season.from <= timeStamp && props.season.to >= timeStamp))) {
            const date = new Date(timeStamp);
            const day = date.getDay();
            d.series[game > 0 ? 0 : 1].data[day] += 1;
          }
        });
      }

      return d;
    });

    const options = {
      offset: 0,
      showArea: true,
      showLine: false,
      showPoint: false,
      fullWidth: true,
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 1,
      }),
      height: 160,
      showGridBackground: true,
      axisX: {
        position: 'start',
        offset: 4,
        labelOffset: {
          x: -10,
          y: -8,
        },
        showLabel: true,
        showGrid: true,
        labelInterpolationFnc: (n) => (n !== 'dim' && n !== 'sam' ? n : null),
      },
      axisY: {
        offset: 0,
        showLabel: false,
        showGrid: false,
      },
      chartPadding: {
        top: 16,
        right: 0,
        bottom: 0,
        left: 0,
      },
    };

    let chart = null;
    onMounted(() => {
      // eslint-disable-next-line no-unused-vars
      chart = new Chartist.Line(root.value, data.value, options);
    });

    watch(() => data.value, (val) => {
      chart.update(val);
    });

    return { root };
  },

};
</script>

<style>
.ct-area {
  fill-opacity: .5;
}
</style>
