<template>
  <span
    :class="[
      'text-xs px-2 py-0.5 text-white rounded-full',
      `bg-${color}`,
    ]">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'green-500',
    },
  },
};
</script>
