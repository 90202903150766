<template>

  <y-card-subtitle>
    Classement
  </y-card-subtitle>

  <div
    v-if="getEvent.seasonality"
    class="p-4 bg-gray-50 border-b">
    <div class="relative">
      <select
        class="border rounded px-4 py-2 w-full appearance-none focus:outline-none cursor-pointer"
        v-model="selectedSeason">
        <option :value="false">
          Toutes saisons confondues
        </option>
        <option
          v-for="season in getSeasons"
          :key="season.id"
          :value="season.id">
          {{ season.name }}
        </option>
      </select>
      <y-icon-chevron-down class="h-4 absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none" />
    </div>
    <p class="text-xs text-gray-400 mt-1">
      du {{ convertTimeStampToShortFormatedDate(getSelectedSeason.from) }}
      au {{ convertTimeStampToShortFormatedDate(getSelectedSeason.to) }}
    </p>
  </div>

  <ul
    v-if="getSortedPlayers.length"
    class="divide-y">
    <transition-group>
      <player-ranking-row
        v-for="(player, i) in getSortedPlayers"
        :key="player.id"
        :index="i"
        :player="player"
        :sorted-players="getSortedPlayers"
        :season="getSelectedSeason" />
    </transition-group>
  </ul>

  <p
    class="p-4"
    v-else>
    Aucun match n'a encore été joué
    <template v-if="getEvent.seasonality">
      pour cette saison
    </template>
  </p>

</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { sortFirebaseObjectBy, sortArrayBy, convertTimeStampToShortFormatedDate } from '@/classes/Tools';
import { getPlayerStats } from '@/classes/Hydrators';

import PlayerRankingRow from '@/components/PlayerRankingRow.vue';
import YCardSubtitle from '@/UI/YCardSubtitle.vue';
import YIconChevronDown from '@/UI/icons/YIconChevronDown.vue';

export default {

  components: {
    PlayerRankingRow,
    YCardSubtitle,
    YIconChevronDown,
  },

  setup() {
    const store = useStore();

    const getEvent = computed(() => store.getters.getEvent);

    const getSeasons = computed(() => sortFirebaseObjectBy(getEvent.value.seasons, 'from'));
    const currentSeason = () => {
      const now = Date.now();
      const s = getSeasons.value.filter((e) => e.from <= now && e.to >= now);
      return s?.[0]?.id || getSeasons.value[0]?.id;
    };
    const selectedSeason = ref(currentSeason());

    watch(() => getEvent.value, (val) => {
      if (val.seasons && !selectedSeason.value) {
        selectedSeason.value = currentSeason();
      }
    });

    const getSelectedSeason = computed(() => {
      if (selectedSeason.value) {
        return getSeasons.value.filter((e) => e.id === selectedSeason.value)?.[0];
      }
      let from = null;
      let to = null;
      getSeasons.value.forEach((s) => {
        from = !from || s.from < from ? s.from : from;
        to = !to || s.to > to ? s.to : to;
      });
      return { from, to };
    });

    const getSortedPlayers = computed(() => {
      const players = sortFirebaseObjectBy(store.getters.getEventPlayers, 'stats.score');
      if (selectedSeason.value) {
        players.forEach((player) => {
          // eslint-disable-next-line no-param-reassign
          player.stats = getPlayerStats(player, getEvent.value, getSelectedSeason.value);
        });
      }
      return sortArrayBy(players, 'stats.score').filter((e) => e.stats.score !== null).reverse();
    });

    return {
      getEvent,
      getSeasons,
      selectedSeason,
      getSelectedSeason,
      getSortedPlayers,
      convertTimeStampToShortFormatedDate,
    };
  },

};
</script>
