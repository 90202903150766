<template>
  <li class="transition">
    <div
      class="px-4 py-3 flex items-center justify-between cursor-pointer hover:bg-gray-100"
      @click="isVisible = !isVisible">
      <span>
        <span
          :class="[
            'font-bold w-8 inline-block',
            `text-${getRankingColor(index)}`,
          ]">
          #{{ index + 1 }}
        </span>
        <y-player-summary :player="player" />
      </span>
      <y-badge
        v-if="player.stats.score"
        :color="getBadgeColor(index)">
        {{ $filters.pluralize(player.stats.score.toFixed(2), 'pt') }}
      </y-badge>
    </div>
    <div
      v-if="isVisible"
      class="pt-3 bg-gray-50 border-t overflow-hidden transition">
      <player-graph
        :player="player"
        :event="getEvent"
        :season="season" />
    </div>
  </li>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { getRankingColor } from '@/classes/Tools';

import PlayerGraph from '@/components/PlayerGraph.vue';
import YPlayerSummary from '@/UI/YPlayerSummary.vue';
import YBadge from '@/UI/YBadge.vue';

export default {

  components: {
    PlayerGraph,
    YPlayerSummary,
    YBadge,
  },

  props: {
    index: {
      type: Number,
      required: true,
    },
    player: {
      type: Object,
      required: true,
    },
    sortedPlayers: {
      type: Array,
      required: true,
    },
    season: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const store = useStore();
    const getEvent = computed(() => store.getters.getEvent);

    const getBadgeColor = (index) => {
      const nbPlayers = props.sortedPlayers.length;
      if (index === 0) return getRankingColor(index);
      if (index < nbPlayers / 2) return 'green-500';
      if (index < nbPlayers / 1.75) return 'red-500';
      if (index < nbPlayers / 1.5) return 'red-600';
      if (index < nbPlayers / 1.25) return 'red-700';
      return 'red-800';
    };

    const isVisible = ref(false);

    return {
      isVisible,
      getEvent,
      getRankingColor,
      getBadgeColor,
    };
  },

};
</script>
